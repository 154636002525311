import { AppDidBecomeActive } from 'AppInterface/AppInterface';
import { IsMinimalRhymeSite, SiteOwner } from 'config';
import { getServerTime } from 'fb';
import { Timestamp } from 'firebase/firestore';
import AnandDate from 'helpers/AnandDate';
import { combineEpics, ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { delay, mergeMap, takeUntil } from 'rxjs/operators';
import { UPDATE_FB_ARTICLES } from 'store/data/articles/actions';
import { UPDATE_BOOKLETS } from 'store/data/booklets/actions';
import { UPDATE_FEATURES } from 'store/data/features/actions';
import { UPDATE_HD } from 'store/data/hd/actions';
import { UPDATE_NOTIFICATIONS } from 'store/data/notifications/actions';
import { UPDATE_EDITIONS, UPDATE_PUBLICATIONS } from 'store/data/publications/actions';
import { UPDATE_QUOTES } from 'store/data/quotes/actions';
import { UPDATE_RHYMES } from 'store/data/rhymes/actions';
import { UPDATE_SANDESH } from 'store/data/sandesh/actions';
import { UPDATE_USER_DATA } from 'store/data/user/actions';
import { FILTER_DATA_INIT } from 'store/temp/actions';
import { ApplicationState, FBData } from 'types';

const filterData = (action$, state$, type, stateSlice) => {
	let recordStore = (state$.value as ApplicationState).dataState[stateSlice];
	let settings = (state$.value as ApplicationState).userState.userStore.userData?.settings;
	let showAll = (settings && settings['admin.records']) ?? false;

	let filtered: FBData[] = [];
	let now = getServerTime();
	let maxTime = new Date('9999-12-31').getTime();
	let nextUpdateTime = maxTime;
	let records = recordStore.byId;
	for (let id in records) {
		let record = records[id] as FBData;
		let publishTime = record.publishTime ? new Timestamp(record.publishTime.seconds, record.publishTime.nanoseconds).toMillis() - 5000 : null;
		let expireTime = record.expireTime ? new Timestamp(record.expireTime.seconds, record.expireTime.nanoseconds).toMillis() : null;

		let forDate = record.forDate;

		if (record.deleted) {
			continue;
		}

		if (record.active === false && !showAll) {
			continue;
		}

		if (IsMinimalRhymeSite && !record.minimal) {
			continue;
		}

		if (!IsMinimalRhymeSite && record.minimal) {
			continue;
		}

		if ((!showAll && record.showon?.sap === false && SiteOwner === 'SAPD') || (record.showon?.spd === false && SiteOwner === 'SPD')) {
			continue;
		}

		if (expireTime && expireTime < now) {
			continue;
		}

		if (publishTime && publishTime > now) {
			if (publishTime < nextUpdateTime) {
				nextUpdateTime = publishTime;
			}

			if (!showAll) {
				continue;
			}
		}

		if (forDate && new AnandDate().format('YYYY-MM-DD') < forDate && !showAll) {
			continue;
		}

		if (forDate && new AnandDate().format('YYYY-MM-DD') === forDate) {
			nextUpdateTime = new AnandDate(forDate).add(24, 'hour').getDateObj().getTime();
		}

		if (expireTime && expireTime < nextUpdateTime) {
			nextUpdateTime = expireTime;
		}

		filtered.push(record);
	}

	let result: any = of({
		type: type + '_FILTERED',
		payload: filtered,
	});
	if (nextUpdateTime < maxTime) {
		let delayDate = new Date();
		delayDate.setTime(nextUpdateTime);
		result = concat(result, of({ type: 'FILTER_' + type }).pipe(delay(delayDate), takeUntil(of({ type: type + '_FILTERED' }))));
	}

	return result;
};

const filterDataEpicFactory = (type, stateSlice) => {
	return (action$, state$) =>
		action$.pipe(
			ofType(type, 'FILTER_' + type, UPDATE_USER_DATA, FILTER_DATA_INIT, AppDidBecomeActive),
			mergeMap(() => filterData(action$, state$, type, stateSlice))
		);
};

export const filterDataEpic = IsMinimalRhymeSite
	? combineEpics(filterDataEpicFactory(UPDATE_RHYMES, 'rhymes'))
	: combineEpics(
			filterDataEpicFactory(UPDATE_FB_ARTICLES, 'articles'),
			filterDataEpicFactory(UPDATE_PUBLICATIONS, 'publications'),
			filterDataEpicFactory(UPDATE_EDITIONS, 'editions'),
			filterDataEpicFactory(UPDATE_RHYMES, 'rhymes'),
			filterDataEpicFactory(UPDATE_NOTIFICATIONS, 'notifications'),
			filterDataEpicFactory(UPDATE_QUOTES, 'quotes'),
			filterDataEpicFactory(UPDATE_HD, 'hd'),
			filterDataEpicFactory(UPDATE_BOOKLETS, 'booklets'),
			// filterDataEpicFactory(UPDATE_ORDERS, 'orders')
			filterDataEpicFactory(UPDATE_FEATURES, 'features'),
			filterDataEpicFactory(UPDATE_SANDESH, 'sandesh')
	  );
