import { Box, Button, Theme } from '@mui/material';
import Card from '@mui/material/Card';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { DBConfig } from 'config';
import { generateRecordId, setRecord } from 'fb';
import { User } from 'firebase/auth';
import { serverTimestamp, Timestamp } from 'firebase/firestore';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import moment from 'moment';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApplicationState, Sandesh, UserData } from 'types';
import * as Yup from 'yup';

const classes = {
	root: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	}),
	card: (theme: Theme) => ({
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: 'white',
	}),
	form: (theme: Theme) => ({
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '100%',

		'& .MuiTextField-root': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
	signinbtn: (theme: Theme) => ({
		height: 42,
		width: '80%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	}),
};

interface SSNRequestProps {
	id?: string;
	record?: Sandesh;
	ssnConfig: any;
	userData?: UserData;
	user?: User;
}

const Component = (props: SSNRequestProps) => {
	const { record, ssnConfig, userData, user } = props;
	let card = record?.cardRequest;
	let navigate = useNavigate();

	let minAllowedDate = moment(ssnConfig.minAllowedDate.seconds * 1000);
	let maxAllowedDate = moment(ssnConfig.maxAllowedDate.seconds * 1000);

	return (
		<Box sx={classes.root}>
			<Card sx={classes.card}>
				<Formik
					initialValues={{
						name: card?.name ?? '',
						permission_date: card?.permission_date ? moment(card?.permission_date) : minAllowedDate,
						people_count: card?.people_count ?? 1,
					}}
					validationSchema={() => {
						return Yup.object({
							name: Yup.string().required('Name is required'),
							permission_date: Yup.date().required('Date is required'),
							people_count: Yup.number()
								.max(ssnConfig.maxAllowedPeople, `Maximum ${ssnConfig.maxAllowedPeople} visitors are allowed!`)
								.min(1, 'Enter atleast 1 visitor!'),
						});
					}}
					onSubmit={async (values, { setSubmitting }) => {
						let recordData = {
							cardRequest: {
								...values,
								permission_date: values.permission_date.format('YYYY-MM-DD'),
								phone: userData?.phone,
								reference: userData?.fullName,
								place: 'ssn',
								by: user?.uid,
								byName: '[SAS App] ' + userData?.fullName,
							},
							deleted: record?.deleted ?? false,
							active: record?.active ?? true,
							createdAt: record?.createdAt ?? serverTimestamp(),
							publishTime: record?.publishTime ?? serverTimestamp(),
							expireTime: record?.expireTime ?? Timestamp.fromDate(maxAllowedDate.toDate()),
							allReceiverIds: record?.allReceiverIds ?? [userData?.phone],
							receiverIds: record?.receiverIds ?? [userData?.phone],
							type: 'SSN',
							modifiedBy: user?.uid,
						};

						await setRecord('sandesh', record?.id ?? generateRecordId('sandesh'), recordData);

						setSubmitting(false);
						navigate(-1);
					}}
				>
					{({ submitForm, isSubmitting, values, setFieldValue }) => (
						<Box component={Form} sx={classes.form} translate="yes">
							<Field component={TextField} autoComplete="off" name="name" type="text" label="Visitor Full Name" />
							<MobileDatePicker
								value={values['permission_date']}
								label="Arrival Date"
								format="ddd, Do MMM YYYY"
								minDate={minAllowedDate}
								maxDate={maxAllowedDate}
								onAccept={(date) => {
									if (date) {
										setFieldValue('permission_date', date);
									}
								}}
							/>
							<Field component={TextField} autoComplete="off" name="people_count" type="number" label="Visitor Count" />
							<Button sx={classes.signinbtn} variant="contained" color="primary" type="submit" onClick={submitForm} disabled={isSubmitting}>
								Submit Request
							</Button>
						</Box>
					)}
				</Formik>
			</Card>
		</Box>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	return {
		record: props.id ? state.dataState.sandesh.byId[props.id] : undefined,
		ssnConfig: state.dataState.configs.byId[DBConfig.SSN].value,
		userData: state.userState.userStore.userData,
		user: state.userState.userStore.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {};
}

export const SSNRequest = connect(mapStateToProps, mapDispatchToProps)(Component);
