import { ImageList, ImageListItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, Publication } from 'types';
import PublicationTile from './PublicationTile';

interface PublicationListProps {
	publications: Publication[];
	concise?: boolean;
	singleLine?: boolean;
}

class PublicationList extends React.Component<PublicationListProps, any> {
	render() {
		let { publications, concise, singleLine } = this.props;

		if (publications && publications.length && concise) {
			if (singleLine) {
				return (
					<ImageList
						sx={{
							gridAutoFlow: 'column',
							padding: '2px 8px',
							gap: '12px !important',
							display: publications.length > 2 ? 'grid' : 'flex',
						}}
					>
						{publications.map((publication: any) => (
							<ImageListItem key={publication.id} sx={{ width: '40vw' }}>
								<PublicationTile concise={concise} record={publication}></PublicationTile>
							</ImageListItem>
						))}
					</ImageList>
				);
			}
			let sapdPubs = publications.filter((record) => record.owner === 'SAPD');
			let spdPubs = publications.filter((record) => record.owner === 'SPD');
			return (
				<>
					<ImageList
						sx={{
							gridAutoFlow: 'column',
							padding: '2px 8px',
							gap: '12px !important',
							display: sapdPubs.length > 2 ? 'grid' : 'flex',
						}}
					>
						{sapdPubs.map((publication: any) => (
							<ImageListItem key={publication.id} sx={{ width: '40vw' }}>
								<PublicationTile concise={concise} record={publication}></PublicationTile>
							</ImageListItem>
						))}
					</ImageList>
					<ImageList
						sx={{
							gridAutoFlow: 'column',
							padding: '2px 8px',
							gap: '12px !important',
							display: spdPubs.length > 2 ? 'grid' : 'flex',
						}}
					>
						{spdPubs.map((publication: any) => (
							<ImageListItem key={publication.id} sx={{ width: '40vw' }}>
								<PublicationTile concise={concise} record={publication}></PublicationTile>
							</ImageListItem>
						))}
					</ImageList>
				</>
			);
		}

		return publications && publications.length ? (
			<div style={{ flexGrow: 1, padding: 8 }}>
				<Grid container spacing={1}>
					{publications.map((publication) => {
						return (
							<Grid key={publication.id} item xs={concise ? 6 : 12} sm={concise ? 4 : 6} lg={concise ? 3 : 4}>
								<PublicationTile concise={concise} record={publication}></PublicationTile>
							</Grid>
						);
					})}
				</Grid>
			</div>
		) : null;
	}
}

function mapStateToProps(state: ApplicationState, props: { filter?: (pub: Publication) => boolean; sort?: (a: Publication, b: Publication) => number }) {
	let pubs = state.filteredDataState.publications;
	if (props && props.filter) {
		pubs = pubs.filter(props.filter);
	}
	if (props && props.sort) {
		pubs = pubs.sort(props.sort);
	} else {
		pubs = pubs.sort((a, b) => a.order - b.order);
	}

	return {
		publications: pubs,
	};
}

export default connect(mapStateToProps)(PublicationList);
