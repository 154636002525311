import { IAnandApp } from './IAnandApp';

declare var webkit: any;

export class IOSAnandApp implements IAnandApp {
	private version: number = -1;
	private AnandApp: any;
	private callbacks = {};
	public appInstallationId;
	public buildVersionCode;

	constructor() {
		// let msgHandlers = webkit.messageHandlers;
		// let versions = Object.keys(msgHandlers);
		// this.version = parseFloat(versions[0].replace('AnandAppV', '').replace('_', '.'));
		this.AnandApp = webkit.messageHandlers.AnandApp; // msgHandlers[versions[0]];

		var message = {
			command: 'getAppVersion',
		};
		this.iosCommandForResult(message).then((value) => {
			this.version = value as number;
		});
	}

	private iosCommand = (message) => {
		this.AnandApp.postMessage(message);
	};

	private iosCommandForResult = async (message) => {
		let command = message.command;
		this.callbacks[command] = this.callbacks[command] || [];
		let result = new Promise((resolve) => {
			this.callbacks[command].push((data) => {
				resolve(data);
			});
		});

		this.AnandApp.postMessage(message);

		return result;
	};

	public initialize = (webappBuildVersion?) => {
		var message = {
			command: 'initialize',
		};

		if (webappBuildVersion) {
			message['webappBuildVersion'] = webappBuildVersion;
		}

		return this.iosCommand(message);
	};

	public getAppVersion = () => {
		return this.version;
	};

	public receiveFromApp(opName, data) {
		let callbacks: any[] = this.callbacks[opName] || [];
		callbacks.forEach((callback) => {
			callback(data);
		});

		delete this.callbacks[opName];
	}

	public getBuildVersionCode = async () => {
		if (this.buildVersionCode) {
			return this.buildVersionCode;
		}

		var message = {
			command: 'getBuildVersionCode',
		};
		this.buildVersionCode = await this.iosCommandForResult(message);
		return this.buildVersionCode;
	};

	public downloadFile = (url, filename) => {
		var message = {
			command: 'downloadFile',
			url: url,
			filename: filename,
		};

		return this.iosCommand(message);
	};

	public downloadMedia = (id, filename, src, force) => {
		force = force || false;

		var message = {
			command: 'downloadMedia',
			id: id + '',
			filename: filename,
			src: src,
			force: force,
		};

		return this.iosCommand(message);
	};

	public getAppInstallationId = async () => {
		if (this.appInstallationId) {
			return this.appInstallationId;
		}

		var message = {
			command: 'getAppInstallationId',
		};

		this.appInstallationId = await this.iosCommandForResult(message);
		return this.appInstallationId;
	};

	public getAppDetailesJson = async () => {
		var message = {
			command: 'getAppDetailesJson',
		};

		return this.iosCommandForResult(message);
	};

	public deleteMedia = (id, filename) => {
		var message = {
			command: 'deleteMedia',
			id: id + '',
			filename: filename,
		};

		return this.iosCommand(message);
	};

	public loadGoogleSignin = () => {
		var message = {
			command: 'loadGoogleSignin',
		};

		return this.iosCommand(message);
	};

	public gSignout = () => {
		var message = {
			command: 'gSignout',
		};

		return this.iosCommand(message);
	};

	public loadAppleSignin = (forRevoke?: boolean) => {
		var message = {
			command: 'loadAppleSignin',
			forRevoke: forRevoke ?? false,
		};

		return this.iosCommand(message);
	};

	public appleSignout = () => {
		var message = {
			command: 'appleSignout',
		};

		return this.iosCommand(message);
	};

	public shareLink = (title, link, type, id) => {
		var message = {
			command: 'shareLink',
			title: title,
			link: link,
		};

		this.logEvent('share', { content_type: type, item_id: id, link, title });
		return this.iosCommand(message);
	};

	public shareImageDataURI = (title: string, dataURI: string, link: string) => {
		var message = {
			command: 'shareImageDataURI',
			title: title,
			link: link,
			dataURI: dataURI,
		};

		this.logEvent('share', { link, title });
		return this.iosCommand(message);
	};

	public getAppEnv = () => {
		return 'iOS';
	};

	public showToast = (toast) => {
		var message = {
			command: 'showToast',
			toast: toast,
		};

		return this.iosCommand(message);
	};

	public showProgress = () => {
		var message = {
			command: 'showProgress',
		};

		return this.iosCommand(message);
	};

	public hideProgress = () => {
		var message = {
			command: 'hideProgress',
		};

		return this.iosCommand(message);
	};
	public showSnack = (message) => {
		let msg = {
			command: 'showSnack',
			message: message,
		};

		return this.iosCommand(msg);
	};

	public putToAppStorage = (key, value) => {
		var message = {
			command: 'putToAppStorage',
			key: key,
			value: value,
		};

		return this.iosCommand(message);
	};

	public removeFromAppStorage = (key) => {
		var message = {
			command: 'removeFromAppStorage',
			key: key,
		};

		return this.iosCommand(message);
	};

	public getFromAppStorage = async (key) => {
		var message = {
			command: 'getFromAppStorage',
			key: key,
		};

		return (await this.iosCommandForResult(message)) as string;
	};

	public subscribeToTopic = (topic) => {
		var message = {
			command: 'subscribeToTopic',
			topic: topic,
		};

		return this.iosCommand(message);
	};

	public unsubscribeFromTopic = (topic) => {
		var message = {
			command: 'unsubscribeFromTopic',
			topic: topic,
		};

		return this.iosCommand(message);
	};

	public playMedia = (audioId, src, filename, title, isFav, playAuto, startTime, albumTitle, artist) => {
		playAuto = typeof playAuto === 'undefined' ? true : playAuto;
		startTime = startTime || 0;

		var message = {
			command: 'playMedia',
			audioId: audioId,
			src: src,
			filename: filename,
			title: title,
			isFav: isFav,
			playAuto: playAuto,
			startTime: startTime,
			albumTitle: albumTitle,
			artist: artist,
		};

		return this.iosCommand(message);
	};

	public playAllMedia = (mediaArryaJson: string, replace: boolean) => {
		var message = {
			command: 'playAllMedia',
			mediaArryaJson,
			replace,
		};

		return this.iosCommand(message);
	};

	public togglePlayPause = () => {
		var message = {
			command: 'togglePlayPause',
		};

		return this.iosCommand(message);
	};

	public downloadCurrentMedia = (title, force) => {
		force = force || false;

		var message = {
			command: 'downloadCurrentMedia',
			title: title,
			force: force,
		};

		return this.iosCommand(message);
	};

	public deleteCurrentMedia = () => {
		var message = {
			command: 'deleteCurrentMedia',
		};

		return this.iosCommand(message);
	};

	public stopAndRelease = () => {
		var message = {
			command: 'stopAndRelease',
		};

		return this.iosCommand(message);
	};

	public seekToTime = (time) => {
		var message = {
			command: 'seekToTime',
			time: time,
		};

		return this.iosCommand(message);
	};

	public setPlaybackSpeed = (speed) => {
		var message = {
			command: 'setPlaybackSpeed',
			speed: speed,
		};

		return this.iosCommand(message);
	};

	public setRepeatMode = (repeatMode: number) => {
		var message = {
			command: 'setRepeatMode',
			repeatMode,
		};

		return this.iosCommand(message);
	};

	public setShuffle = async (shuffle: boolean) => {
		var message = {
			command: 'setShuffle',
			shuffle,
		};

		return (await this.iosCommandForResult(message)) as string[];
	};

	public playNextMedia = () => {
		var message = {
			command: 'playNextMedia',
		};

		return this.iosCommand(message);
	};

	public playPrevMedia = () => {
		var message = {
			command: 'playPrevMedia',
		};

		return this.iosCommand(message);
	};

	public hasNextMedia = async () => {
		var message = {
			command: 'hasNextMedia',
		};

		return (await this.iosCommandForResult(message)) as boolean;
	};

	public hasPrevMedia = async () => {
		var message = {
			command: 'hasPrevMedia',
		};

		return (await this.iosCommandForResult(message)) as boolean;
	};

	public getMediaIdAt = async (i: number) => {
		var message = {
			command: 'getMediaIdAt',
			i,
		};

		return (await this.iosCommandForResult(message)) as string;
	};

	public getCurMediaId = async () => {
		var message = {
			command: 'getCurMediaId',
		};

		return (await this.iosCommandForResult(message)) as string;
	};

	public getMediaCount = async () => {
		var message = {
			command: 'getMediaCount',
		};

		return (await this.iosCommandForResult(message)) as number;
	};

	public getCurMediaIndex = async () => {
		var message = {
			command: 'getCurMediaIndex',
		};

		return (await this.iosCommandForResult(message)) as number;
	};
	public seekToMediaItemIndex = (itemIndex: number, time: number) => {
		var message = {
			command: 'seekToMediaItemIndex',
			itemIndex,
			time,
		};

		return this.iosCommand(message);
	};

	public exportMedia = async (id, filename, src) => {
		var message = {
			command: 'exportMedia',
			id: id + '',
			filename: filename,
			src: src,
		};

		return this.iosCommand(message);
	};

	public logEvent = (name, params) => {
		var message = {
			command: 'logEvent',
			name: name,
			params: params,
		};

		return this.iosCommand(message);
	};

	public setUserProperty = (name, value) => {
		var message = {
			command: 'setUserProperty',
			name: name,
			value: value,
		};

		return this.iosCommand(message);
	};

	public clearAppData() {
		var message = {
			command: 'clearAppData',
		};

		return this.iosCommand(message);
	}

	public clearWebViewCache() {
		var message = {
			command: 'clearWebViewCache',
		};

		return this.iosCommand(message);
	}
}
