import { IconButton, Slider } from '@mui/material';
import App from 'AppInterface/App';
import { AudioPlaybackInstance } from 'components/player/AudioPlayback';
import { MediaPlayer } from 'components/player/MediaPlayer';
import AddToPlaylistControl from 'components/playlist/AddToPlaylistControl';
import { BottomDrawerOptionProps, BottomDrawerOptions } from 'components/shell/BottomDrawerOptions';
import { ArticleTypeMap } from 'config';
import { Icons } from 'config/icons';
import { getArticleShareUrl, getShortUrl } from 'helpers';
import { User } from 'firebase/auth';
import { getContentStorageUrl, isAdmin, isFakeWebViewEnv, isWebEnv, timeout } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { toggleRecordPin } from 'services/api/auth';
import { addOfflineAudio as addOfflineAudioAction, removeOfflineAudio } from 'store/offline/actions';
import { toggleBottomDrawer, updateAudioPlaybackSpeed } from 'store/temp/actions';
import { ApplicationState, Article, ContentType, DownloadStatus, PinnedRecord } from 'types';

export const getArticleIcon = (record) => {
	if (record?.mediaType === 'audio') {
		if (record?.articleType === 'bhajan') {
			return Icons.Bhajan;
		} else if (record?.articleType === 'satsang') {
			return Icons.Satsang;
		} else {
			return Icons.Satsang;
		}
	} else if (record?.mediaType === 'video') {
		return Icons.Video;
	} else {
		return Icons.Publication;
	}
};

export const getArticleIconImg = (record) => {
	if (record?.articleType === 'shriaarti') {
		return <img src={getContentStorageUrl(ContentType.PKP, 'webapp/diya.png')} />;
	}
	if (record?.articleType === 'shriamarvani') {
		return <img src={getContentStorageUrl(ContentType.PKP, 'webapp/amarvani.jpeg')} />;
	}
	if (record?.articleType === 'shriamarvani' || record?.articleType === 'dhyan') {
		return <img src={getContentStorageUrl(ContentType.PKP, 'webapp/app_icon.png')} />;
	}
	if (record?.mediaType === 'audio') {
		if (record?.articleType === 'bhajan') {
			return <img src={getContentStorageUrl(ContentType.PKP, 'webapp/flute.png')} />;
		} else if (record?.articleType === 'satsang') {
			return <img src={getContentStorageUrl(ContentType.PKP, 'webapp/lotus.jpg')} />;
		} else {
			return <img src={getContentStorageUrl(ContentType.PKP, 'webapp/lotus.jpg')} />;
		}
	} else if (record?.mediaType === 'video') {
		return Icons.Video;
	} else {
		return Icons.Publication;
	}
};

const optionsObj = (
	record: Article,
	toggleDrawer: (content?: any, open?: boolean) => void,
	isDownloaded: boolean,
	isDownloading: boolean,
	removeOfflineAudio: (articleId: string) => void,
	addOfflineAudio: (article: Article, forExport: boolean) => void,
	currentPlaybackSpeed: number,
	updateAudioPlaybackSpeed: (speed: number) => void,
	isAdmin: boolean,
	pinnedRecords: { [id: string]: PinnedRecord },
	toggleRecordPin: (recordId: string) => void,
	user?: User,
	playedTime?: number,
	isPlaying?: boolean,
	currentTime?: number
) => {
	let result: BottomDrawerOptionProps = {
		options: [
			{
				title: 'Share',
				icon: Icons.Share,
				onClick: async () => {
					let url = getArticleShareUrl(record);
					let shortUrl = record.shortUrl;

					if (!shortUrl) {
						shortUrl = await getShortUrl(record, url);
					}

					try {
						App.shareLink('', shortUrl, 'Article:' + ArticleTypeMap[record?.articleType || ''], record.id);
					} catch (error) {
						(App as any).shareLink('', shortUrl);
					}
					toggleDrawer();
				},
			},
		],
		actionHandler: () => {
			toggleDrawer();
		},
	};

	if (['bhajan', 'satsang', 'shriamarvani'].indexOf(record.articleType) >= 0) {
		result.options.push({
			title: pinnedRecords[record.id] ? 'Unpin from Home' : 'Pin to Home',
			icon: pinnedRecords[record.id] ? Icons.BookmarkRemove : Icons.BookmarkAdd,
			onClick: () => {
				toggleRecordPin(record!.id);
				toggleDrawer();
			},
		});
	}

	if (record && (record.allowExport || (isAdmin && isWebEnv() && record.mediaUri))) {
		result.options.unshift({
			title: 'Export',
			icon: Icons.CloudDownload,
			disabled: isDownloading,
			onClick: () => {
				addOfflineAudio(record, true);
				toggleDrawer();
			},
		});
	}

	if (record?.mediaType === 'audio' || record?.mediaType === 'video') {
		if (isFakeWebViewEnv() && (isDownloaded || user || record.allowExport)) {
			result.options.unshift({
				title: isDownloaded || isDownloading ? 'Remove from offline play' : 'Save for offline play',
				icon: isDownloaded ? Icons.DeleteForever : Icons.Download,
				// disabled: isDownloading,
				onClick: () => {
					if (isDownloaded || isDownloading) {
						removeOfflineAudio(record.id);
					} else {
						addOfflineAudio(record, false);
					}
					toggleDrawer();
				},
			});
		}

		if (user && record?.mediaType === 'audio') {
			result.options.unshift({
				title: 'Add to playlist',
				icon: Icons.Queue,
				onClick: async () => {
					// Close current drawer
					toggleDrawer();

					await timeout(200);
					toggleDrawer(<AddToPlaylistControl article={record} />);
				},
			});
		}

		if (isPlaying && record?.mediaType === 'audio') {
			let curSpeed = currentPlaybackSpeed;
			result.options.unshift({
				element: (
					<Slider
						sx={{ mx: 2 }}
						key={'pb-' + curSpeed}
						value={curSpeed}
						valueLabelDisplay="off"
						track={false}
						step={0.25}
						marks={((min: number, max: number, step: number) =>
							Array.from(new Array((max - min) / step + 1)).map((v, i) => ({
								value: min + i * step,
								label: i % 2 ? undefined : min + i * step + 'x',
							})))(0.5, 3, 0.25)}
						min={0.5}
						max={3}
						valueLabelFormat={(value: number) => value + 'x'}
						onChangeCommitted={async (event, value) => {
							if (value !== curSpeed) {
								curSpeed = value as number;
								updateAudioPlaybackSpeed(curSpeed);
								AudioPlaybackInstance().setPlaybackSpeed(curSpeed);
							}

							await timeout(20);
							toggleDrawer(undefined, false);
						}}
					/>
				),
				icon: Icons.DoubleArrow,
			});
		}
	}

	if (playedTime && playedTime > 5 && record?.mediaType === 'audio' && record.mediaLength - playedTime > 5 && !isPlaying) {
		result.options.unshift({
			title: 'Resume Play',
			icon: Icons.Play,
			onClick: () => {
				if (!isPlaying) {
					MediaPlayer.instance().playAudio(record.id, record.articleType, playedTime);
				} else {
					AudioPlaybackInstance().seekTo(playedTime - 3);
				}
				toggleDrawer();
			},
		});
	}

	return result;
};

interface Props {
	addOfflineAudio: (article: Article, forExport: boolean) => void;
	removeOfflineAudio: (articleId: string) => void;
	toggleDrawer: (content?: any, open?: boolean) => void;
	isDownloaded: boolean;
	isDownloading: boolean;
	user?: User;
	isAdmin: boolean;
	record: Article;
	playedTime: number;
	isPlaying: boolean;
	currentTime?: number;
	currentPlaybackSpeed: number;
	pinnedRecords: { [id: string]: PinnedRecord };
	toggleRecordPin: (recordId: string) => void;
	updateAudioPlaybackSpeed: (speed: number) => void;
}
const OptionsIconComp = (props: Props) => {
	let {
		record,
		toggleDrawer,
		isDownloaded,
		removeOfflineAudio,
		addOfflineAudio,
		isDownloading,
		currentPlaybackSpeed,
		updateAudioPlaybackSpeed,
		user,
		isAdmin,
		playedTime,
		isPlaying,
		currentTime,
		pinnedRecords,
		toggleRecordPin,
	} = props;

	const onMoreIconClick = (e: React.MouseEvent) => {
		e.stopPropagation();

		let options = optionsObj(
			record,
			toggleDrawer,
			isDownloaded,
			isDownloading,
			removeOfflineAudio,
			addOfflineAudio,
			currentPlaybackSpeed,
			updateAudioPlaybackSpeed,
			isAdmin,
			pinnedRecords,
			toggleRecordPin,
			user,
			playedTime,
			isPlaying,
			currentTime
		);

		let optionsList = BottomDrawerOptions(options);

		toggleDrawer(optionsList);
	};

	return (
		<IconButton onClick={onMoreIconClick} size="large">
			{Icons.MoreVert}
		</IconButton>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	let downloadState = props.record ? state.offlineState.audio[props.record.id] : undefined;

	let pbDetails = state.tempState.audioPlaybackDetails;
	let playingRecord = pbDetails?.articleList[pbDetails?.currentIndex];

	let pinnedRecords = state.userState.userStore.userData?.pinnedRecords ?? {};

	return {
		pinnedRecords,
		isDownloaded: downloadState?.progress?.status === DownloadStatus.Complete ?? false,
		isDownloading:
			downloadState?.progress?.status === DownloadStatus.InProgress ||
			downloadState?.exportProgress?.status === DownloadStatus.InProgress ||
			downloadState?.progress?.status === DownloadStatus.Queued ||
			downloadState?.exportProgress?.status === DownloadStatus.Queued,
		user: state.userState.userStore.user,
		isAdmin: isAdmin(state.userState.userStore.user, state.userState.userStore.userData),
		playedTime: props.record && props.record.id ? state.offlineState.playedMedia[props.record.id]?.time : 0,
		isPlaying: playingRecord && props.record && playingRecord.id === props.record?.id,
		currentTime: state.tempState.audioPlaybackState?.currentTime,
		currentPlaybackSpeed: state.tempState.audioPlaybackState?.playbackSpeed ?? 1,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any, open?: boolean) => {
			dispatch(toggleBottomDrawer(content, open));
		},
		addOfflineAudio: (article: Article, forExport: boolean) => {
			dispatch(addOfflineAudioAction(article, forExport));
		},
		removeOfflineAudio: (articleId: string) => {
			dispatch(removeOfflineAudio(articleId, false));
		},
		updateAudioPlaybackSpeed: (speed: number) => {
			dispatch(updateAudioPlaybackSpeed(speed));
		},
		toggleRecordPin: (recordId: string) => {
			dispatch(toggleRecordPin(recordId, 'articlespin'));
		},
	};
}

export const ArticleOptionsIcon = connect(mapStateToProps, mapDispatchToProps)(OptionsIconComp);
