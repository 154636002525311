import Fab from '@mui/material/Fab';
import { LangMap, MediaTypeMap } from 'config';
import { Icons } from 'config/icons';
import MiniSearch from 'minisearch';
import React from 'react';
import { connect } from 'react-redux';
import { getArticleGroupsForType, getArticlesByType, getArticlesFiltered, getArticlesFilteredSecond } from 'store/data/articles/selectors';
import { getDataSorted } from 'store/data/generic/selectors';
import { getUserPlaylistsByTrackId } from 'store/data/user/selectors';
import stringSimilarity from 'string-similarity-js';
import { ApplicationState, Article, UserPlaylist } from 'types';
import { FilterBar } from '../FilterBar';
import { MediaPlayer } from '../player/MediaPlayer';
import RecordList, { RecordListProps } from '../RecordList';
import { ArticleTile } from './ArticleTile';

interface ArticleListProps {
	recordType: string;
	records: Article[];
	audioRecords: Article[];
	origRecords?: Article[];
	avoidVirtual?: boolean;
	avoidFab?: boolean;
	avoidFilterBar?: boolean;
	showExtras: boolean;
	tile?: RecordListProps['RecordTile'];
	playlists?: { [articleId: string]: UserPlaylist[] };
}

const filtersMeta = {
	mediaType: {
		label: 'Type',
		type: 'checkbox',
		renderType: 'togglebar',
		optionLabel: (option) => MediaTypeMap[option],
	},
	lang: {
		label: 'Language',
		type: 'custom',
		renderType: 'togglebar',
		// type: 'checkbox',
		// renderType: 'togglebar',
		value: (record) => {
			if (record.lang && record.lang !== 'undefined') {
				return record.lang;
			}
			return record.title?.en ? 'en' : 'hi';
		},
		dependsOn: ['mediaType', 'publishTime'],
		optionLabel: (option) => LangMap[option],
	},
	playbackState: {
		label: 'Playback Status',
		type: 'custom',
		renderType: 'togglebar',
		value: (record) => {
			// let playedTime = state.dataState.playedMedia[record.id] ?? 0;
			// if (playedTime <= 0) {
			// 	return 'Not Started';
			// }
			// if (record.mediaLength - playedTime < 5) {
			// 	return 'Completed';
			// }
			// return 'Partial';
			return record.__playbackState ?? 'Not Started';
		},
		dependsOn: ['mediaType', 'publishTime', 'lang'],
		optionLabel: (option) => option,
	},
	publishTime: {
		label: 'Date',
		type: 'daterange',
	},
	group: {
		label: 'Category/Genre',
		type: 'checkbox',
		dependsOn: ['mediaType', 'lang', 'publishTime'],
		optionLabel: (option) => option,
	},
};

const classes = {
	fab: {
		position: 'fixed' as 'fixed',
		bottom: '75px; bottom: calc(75px + env(safe-area-inset-bottom)/2)',
		right: 30,
		zIndex: 1,
	},
};

class ArticleList extends React.Component<ArticleListProps> {
	private getItemSize(index) {
		let { records, playlists } = this.props;

		let record = records[index];
		if (!record) {
			return 139 - 16 - 4;
		}

		if (record && !record.id) {
			return 32;
		}

		// if (record.title.en && record.group.length > 35) {
		// 	return 175;
		// }

		let playlistsHeight = 0;
		if (playlists) {
			let articlePlaylists = playlists[record.id]?.filter((playlist) => playlist.id !== '0');
			if (articlePlaylists?.length > 0) {
				playlistsHeight = 24;
			}
		}

		return 155 + playlistsHeight + (this.props.showExtras && record.author ? 25 : 0) + 15 - 16;
	}

	render() {
		let { avoidVirtual, avoidFab, records, audioRecords, avoidFilterBar, tile } = this.props;

		return (
			<>
				{avoidFab === true || !audioRecords.length ? null : (
					<Fab
						color="primary"
						sx={classes.fab}
						onClick={() => {
							MediaPlayer.instance().playRecords(audioRecords);
						}}
					>
						{Icons.Play}
					</Fab>
				)}
				{avoidVirtual === true ? (
					<>
						{records.map((record, index) => {
							let Tile = tile ?? ArticleTile;
							return <Tile key={record?.id ?? index} index={index} record={record} />;
						})}
					</>
				) : (
					<>
						{avoidFilterBar === true ? null : (
							<FilterBar
								sortBy={[
									'Newest First',
									'Oldest First',
									'Title - Ascending',
									'Title - Descending',
									'Duration - Low to High',
									'Duration - High to Low',
								]}
								sortValue="Newest First"
								recordType={'articles-' + this.props.recordType}
								records={this.props.origRecords}
								filtersMeta={filtersMeta}
							/>
						)}
						<div style={{ height: avoidFilterBar ? '100%' : 'calc(100% - 56px)' }}>
							<RecordList
								records={records}
								fetching={false}
								RecordTile={tile ?? ArticleTile}
								getItemSize={this.getItemSize.bind(this)}
							></RecordList>
						</div>
					</>
				)}
			</>
		);
	}
}

const audioFilter = { mediaType: { audio: true, text: false, video: false } };

function mapStateToProps(state: ApplicationState, props) {
	let showExtras = props.showExtras ?? (state.userState.userStore.userData?.settings && state.userState.userStore.userData?.settings['admin.records']);
	if (props.records) {
		return {
			records: props.records,
			origRecords: props.records,
			audioRecords: getArticlesFiltered(state, props.records, { mediaType: { audio: true, text: false, video: false } }, filtersMeta),
			showExtras,
			avoidFilterBar: true,
			playlists: getUserPlaylistsByTrackId(state),
		};
	}

	let recordType = 'articles-' + props.recordType;
	let filters = state.uxState.filters[recordType];

	let articlesByType = getArticlesByType(state);
	let records = articlesByType[props.recordType];
	let audioRecords: Article[] = [];
	if (records) {
		let sortValue = (state.uxState.sort && state.uxState.sort[recordType]) ?? 'Newest First';
		records = getDataSorted(records, sortValue, recordType + '-' + sortValue) as Article[];
		records = filters ? getArticlesFiltered(state, records, filters, filtersMeta) : records;

		if (props.filter) {
			records = records.filter(props.filter);
		}

		if (state.tempState.searchTerm.length > 0) {
			// let isInTitle = (title) =>
			// 	title && typeof title === 'string' && title.toLocaleLowerCase().indexOf(state.tempState.searchTerm.toLocaleLowerCase()) >= 0;

			// records = records.filter((record) => {
			// 	let inEnTitle = isInTitle(record.title?.en);
			// 	let inHiTitle = isInTitle(record.title?.hi);
			// 	let inTitle = isInTitle(record.title);
			// 	return inEnTitle || inHiTitle || inTitle;
			// });

			let miniSearch = new MiniSearch({
				fields: ['enTitle', 'hiTitle'],
				storeFields: ['title'],
				searchOptions: {
					fuzzy: 0.17,
					combineWith: 'AND',
					prefix: true,
				},
				extractField: (doc, field) => {
					if (field === 'id') {
						return doc.id;
					}
					if (typeof doc.title === 'string' || field === 'title') {
						return doc.title;
					}

					return field === 'enTitle' ? doc.title.en : doc.title.hi;
				},
			});
			miniSearch.addAll(records);

			let filtered = miniSearch.search(state.tempState.searchTerm);

			filtered.forEach((v) => {
				let ratingEn = stringSimilarity(state.tempState.searchTerm, v.title?.en ?? '', Math.ceil(state.tempState.searchTerm.length * 0.7));
				let ratingHi = stringSimilarity(state.tempState.searchTerm, v.title?.hi ?? '', Math.ceil(state.tempState.searchTerm.length * 0.7));
				v.rating = Math.max(ratingEn, ratingHi);
			});

			records = filtered
				.sort((a, b) => (b.rating != a.rating ? b.rating - a.rating : b.score - a.score))
				.map((v) => {
					let doc = state.dataState.articles.byId[v.id];
					// let match = v.match;
					// let terms = v.terms;
					// let title = { ...v.title };
					// terms.forEach((term) => {
					// 	if (title.en) {
					// 		title.en = title.en.replaceAll(new RegExp(`(${term})`, 'ig'), "<span class='highlight-term'>$1</span>");
					// 	}
					// 	if (title.hi) {
					// 		title.hi = title.hi.replaceAll(new RegExp(`(${term})`, 'ig'), "<span class='highlight-term'>$1</span>");
					// 	}
					// });
					// return { ...doc, title: title };

					return doc;
				});
		}

		// audioRecords = records;
		audioRecords = getArticlesFilteredSecond(state, records, audioFilter, filtersMeta);
	} else {
		records = Array.from(new Array(4));
	}

	return {
		records,
		audioRecords,
		groups: getArticleGroupsForType(state, props.recordType),
		origRecords: articlesByType[props.recordType],
		showExtras,
		playlists: getUserPlaylistsByTrackId(state),
	};
}

export default connect(mapStateToProps)(ArticleList);
