import { logEvent } from 'components/telemetry/index';
import { DBConfig, IsMinimalRhymeSite } from 'config';
import {
	collection,
	doc,
	DocumentChange,
	DocumentChangeType,
	DocumentData,
	DocumentReference,
	Firestore,
	getDocs,
	onSnapshot,
	orderBy,
	Query,
	query,
	serverTimestamp,
	setDoc,
	SnapshotMetadata,
	Timestamp,
	where,
} from 'firebase/firestore';
import { getContentStorageUrl, getMediaBasePathR2, isAdminOrTest, isFakeWebViewEnv } from 'helpers';
import { ThunkDispatch } from 'redux-thunk';
import { ArticlesFireStoreModule } from 'services/api/articles';
import { BookletsFireStoreModule } from 'services/api/booklets';
import { ConfigsFireStoreModule } from 'services/api/configs';
import { FeaturesFireStoreModule } from 'services/api/features';
import { HDFireStoreModule } from 'services/api/hd';
import { NotificationsFireStoreModule } from 'services/api/notifications';
import { EditionsFireStoreModule, PublicationsFireStoreModule } from 'services/api/publications';
import { QuotesFireStoreModule } from 'services/api/quotes';
import { RhymesFireStoreModule } from 'services/api/rhymes';
import { TrackerFireStoreModule } from 'services/api/tracker';
import { updateFBArticles } from 'store/data/articles/actions';
import { updateBooklet } from 'store/data/booklets/actions';
import { updateFeatures } from 'store/data/features/actions';
import { updateHD } from 'store/data/hd/actions';
import { updateNotifications } from 'store/data/notifications/actions';
import { updateOperation } from 'store/data/ops/actions';
import { updateEditions, updatePublications } from 'store/data/publications/actions';
import { updateQuotes } from 'store/data/quotes/actions';
import { updateRhymes } from 'store/data/rhymes/actions';
import { ApplicationState, ContentType, Operation } from 'types';
import firebase, { fireAuth, fireDB } from './initconfig';
// import 'firebase/analytics';

type FBDocChange = DocumentChange<DocumentData>;
type FBTimestamp = Timestamp;
const fbFromDate = Timestamp.fromDate;

export interface FireStoreModule {
	getModuleName: () => string;
	getOpsKeySuffix: () => string;
	getFBWatchQuery: (db: Firestore) => Query<DocumentData>;
	getChangeProcessAction: (changes: FBDocChange[]) => any;
}

const convertToTimestamp = (record) => {
	for (let key in record) {
		if (record[key] && typeof record[key] === 'object' && typeof record[key]._nanoseconds !== 'undefined') {
			record[key] = new Timestamp(record[key]._seconds, record[key]._nanoseconds);
		} else if (record[key] && typeof record[key] === 'object') {
			convertToTimestamp(record[key]);
		}
	}
};

class SnapChange implements FBDocChange {
	constructor(private record) {
		convertToTimestamp(record);
	}

	public type: DocumentChangeType = 'added';
	public oldIndex = 0;
	public newIndex = 0;
	public doc = {
		data: () => {
			return this.record;
		},
		exists: () => true,
		ref: null as unknown as DocumentReference,
		id: this.record.id,
		metadata: '' as unknown as SnapshotMetadata,
		isEqual: () => false,
		get: () => {},
	};
}

export class InitFireStoreModules {
	private static readonly BufferTime: number = 1;
	private getState: () => ApplicationState;
	private dispatch: ThunkDispatch<ApplicationState, any, any>;
	private unsubscribe: { [moduleName: string]: () => void } = {};
	// private allmodules: { [moduleName: string]: FireStoreModule | null } = {};

	constructor(getState: () => ApplicationState, dispatch: ThunkDispatch<ApplicationState, any, any>) {
		this.getState = getState;
		this.dispatch = dispatch;
	}

	private getUniqueKey = (module: FireStoreModule) => module.getModuleName() + '+' + module.getOpsKeySuffix();

	private getOpsKey(module: FireStoreModule) {
		return 'last-fb-fetch-timestamp::' + this.getUniqueKey(module);
	}

	private async processChanges(module: FireStoreModule, fetchTimeKey: string, changes: FBDocChange[], logData: boolean = false) {
		await this.dispatch(module.getChangeProcessAction(changes));

		let now = new Date();
		let fbFetchOp = this.getState().opsState.byId[fetchTimeKey] || { key: fetchTimeKey, createdAt: now };
		let maxUpdatedTime: FBTimestamp = fbFetchOp.value;
		if (!maxUpdatedTime) {
			maxUpdatedTime = fbFromDate(new Date(0));
		}

		changes.forEach((change) => {
			if (change.type === 'added' || change.type === 'modified') {
				let data = change.doc.data();
				logData && console.log(data);

				let updatedAt: FBTimestamp = data.updatedAt;
				if (updatedAt.seconds > maxUpdatedTime.seconds) {
					maxUpdatedTime = updatedAt;
				} else if (updatedAt.seconds === maxUpdatedTime.seconds && updatedAt.nanoseconds > maxUpdatedTime.nanoseconds) {
					maxUpdatedTime = updatedAt;
				}

				// 	if (module.getModuleName() === 'configs' && change.doc.id.endsWith(DBConfig.UpdatedAt(''))) {
				// 		let collection = change.doc.id.replace(DBConfig.UpdatedAt(''), '');
				// 		let module = this.allmodules[collection];
				// 		if (!module) {
				// 			this.allmodules[collection] = null;
				// 		} else {
				// 			if (!this.unsubscribe[this.getUniqueKey(module)]) {
				// 				this.initModule(module, false, undefined, undefined, true);
				// 			}
				// 		}
				// 	}
			}
			if (change.type === 'removed') {
				// This case won't be there as we are not removing anything
				// In case this comes up due to direct manupulation on server
				// we ignore this even and do not change update time
				// next refresh time, we'll not get any changes from cur updated time
				// and we still keep the upgrade time as it is
			}
		});

		fbFetchOp.value = maxUpdatedTime;
		fbFetchOp.updatedAt = now;

		await this.dispatch(updateOperation(fbFetchOp));

		return maxUpdatedTime;
	}

	private async initModule(
		module: FireStoreModule,
		initWithSnapshot?: boolean,
		snapshotTime?: number,
		snapShotJson?: any
		// forceSubscribe?: boolean
	) {
		return new Promise(async (resolve, reject) => {
			try {
				let db = fireDB;

				let fetchTimeKey = this.getOpsKey(module);
				let fbFetchOp = this.getState().opsState.byId[fetchTimeKey] || {};
				let fetchTime: FBTimestamp = fbFetchOp.value;

				let moduleName = module.getModuleName();

				if (initWithSnapshot && snapshotTime && snapShotJson) {
					let snapData = snapShotJson[module.getModuleName()];
					if (snapData) {
						let changes = snapData.map((record) => new SnapChange(record));

						console.log('Processing snapshot for module(' + moduleName + '):');
						await this.processChanges(module, fetchTimeKey, changes, false);

						fetchTime = fbFromDate(new Date(snapshotTime));
					} else {
						if (!fetchTime) {
							fetchTime = fbFromDate(new Date(0));
						} else {
							fetchTime = new Timestamp(fetchTime.seconds, fetchTime.nanoseconds);
						}
					}
				} else {
					if (!fetchTime) {
						fetchTime = fbFromDate(new Date(0));
					} else {
						fetchTime = new Timestamp(fetchTime.seconds, fetchTime.nanoseconds);
					}
				}
				//  else {
				// 	fetchTime = new firebase.firestore.Timestamp(fetchTime.seconds, fetchTime.nanoseconds);
				// 	// let bufferedMillis = (fetchTime.seconds - InitFireStoreModules.BufferTime) * 1000;
				// 	// fetchTime = firebase.firestore.Timestamp.fromMillis(bufferedMillis);
				// }

				let watchQuery = module.getFBWatchQuery(db);

				let promiseNotHandled = true;

				// if (module.getModuleName() !== 'configs' && forceSubscribe !== true && this.allmodules[module.getModuleName()] !== null) {
				// 	this.allmodules[module.getModuleName()] = module;
				// 	resolve(true);
				// 	return;
				// }

				this.unsubscribe[this.getUniqueKey(module)] = onSnapshot(
					query(watchQuery, where('updatedAt', '>', fetchTime), orderBy('updatedAt', 'desc')),
					// .limit(50)
					async (snapshot) => {
						try {
							console.log('Updates for module(' + moduleName + '):');
							if (snapshot.metadata.fromCache === true) {
								// console.log('Ignoring updates from cache');
								// return;
							}
							let updates = snapshot.docChanges();

							if (updates.length === 0) {
								// return;
							}

							await this.processChanges(module, fetchTimeKey, updates, true);
							promiseNotHandled && resolve(true);
							promiseNotHandled = false;
							console.log('Processed updates for module(' + moduleName + ')', updates.length);
						} catch (error) {
							console.error(moduleName + ' FB: onSnapshot: ' + error.message, error.stack);
							promiseNotHandled && reject(error);
							promiseNotHandled = false;
						}
					},
					(error) => {
						console.error(moduleName + ' FB: onSnapshot: ' + error.message, error.stack);
						promiseNotHandled && reject(error);
						promiseNotHandled = false;
					}
				);
			} catch (error) {
				console.error('FB: initModule: ' + error.message, error.stack);
				reject(error);
			}
		});
	}

	public async processSnapshot(process: (initWithSnapshot, snapShotJson, timestamp, incSnapShotJson) => Promise<void>) {
		let initWithSnapshot = true;
		let snapShotJson: any = undefined;
		let incSnapShotJson: any = undefined;
		let timestamp: number | undefined = undefined;
		let fbFetchOp: Operation | undefined = undefined;
		let forWebView = isFakeWebViewEnv() || isAdminOrTest(this.getState());
		let now = new Date();

		try {
			let configs = (this.getState() as ApplicationState)?.dataState?.configs?.byId ?? {}; //getDataById(this.getState(), 'configs') || {};
			let snapshotObj: any;
			let fetchTimeKey;
			if (forWebView) {
				snapshotObj = configs[DBConfig.Snapshot];
				if ((snapshotObj?.timestamp ?? 0) < 1723561483560) {
					snapshotObj = {
						timestamp: 1723561483560,
						uri: 'Snapshot - 1723561493341.json',
					};
				}

				fetchTimeKey = 'snapshot';
			} else {
				snapshotObj = configs[DBConfig.WebSnapshot];
				if ((snapshotObj?.timestamp ?? 0) < 1723561483560) {
					snapshotObj = {
						timestamp: 1723561483560,
						uri: 'WebSnapshot - 1723561491620.json',
					};
				}

				fetchTimeKey = 'websnapshot';
			}

			let uri = snapshotObj?.uri;
			timestamp = snapshotObj?.timestamp;
			if (timestamp && uri) {
				fbFetchOp = this.getState().opsState.byId[fetchTimeKey] || {};
				let fetchTime: number = fbFetchOp.value;
				if (!fetchTime || fetchTime < timestamp) {
					console.log('Fetching snapshot of time: ' + timestamp);
					let snapshotUrl = getContentStorageUrl(ContentType.Data, uri);
					let result = await window.fetch(snapshotUrl);
					snapShotJson = await result.json();

					fbFetchOp = this.getState().opsState.byId[fetchTimeKey] || {
						key: fetchTimeKey,
						createdAt: now,
					};
					fbFetchOp.value = timestamp;
					fbFetchOp.updatedAt = now;

					// this.dispatch(updateFBArticles([], true));
					// this.dispatch(updateRhymes([], true));
					// this.dispatch(updatePublications([], true));
					// this.dispatch(updateEditions([], true));
					// this.dispatch(updateBooklet([], true));
					// this.dispatch(updateNotifications([], true));
					// this.dispatch(updateQuotes([], true));
					// this.dispatch(updateFeatures([], true));
					// this.dispatch(updateHD([], true));
				}
			}
		} catch (error) {
			console.error('FB: initModules: ' + error.message, error.stack);
			initWithSnapshot = false;
		}
		try {
			let incSnapshotUri = `${getMediaBasePathR2()}Incremental${forWebView ? 'Snapshot' : 'Snapshot'} - ${timestamp}.json`;
			let result = await window.fetch(incSnapshotUri);
			let jsonData = await result.json();
			let incTimestamp: number = 0;
			for (let collection in jsonData) {
				if (collection === '__now__') {
					incTimestamp = jsonData[collection];
					break;
				}
			}

			let incFetchTimeKey = 'IncrementalSnapshot';
			let incFbFetchOp: Operation | undefined = this.getState().opsState.byId[incFetchTimeKey] || {};
			let fetchTime: number = incFbFetchOp.value;
			if (!fetchTime || fetchTime < incTimestamp) {
				incSnapShotJson = jsonData;

				incFbFetchOp = this.getState().opsState.byId[incFetchTimeKey] || {
					key: incFetchTimeKey,
					createdAt: now,
				};
				incFbFetchOp.value = incTimestamp;
				incFbFetchOp.updatedAt = now;

				this.dispatch(updateOperation(incFbFetchOp));
			}
		} catch (error) {
			console.error('Incremental Snapshot Error', error);
		}

		await process(initWithSnapshot, snapShotJson, timestamp, incSnapShotJson);

		if (snapShotJson && fbFetchOp) {
			await this.dispatch(updateOperation(fbFetchOp));
		}
	}

	public async initModules(modules: FireStoreModule[], initWithSnapshot?: boolean) {
		try {
			if (initWithSnapshot === true) {
				await this.processSnapshot(async (initWithSnapshot, snapShotJson, timestamp) => {
					let allInits: any[] = [];
					for (let i = 0; i < modules.length; i++) {
						allInits.push(this.initModule(modules[i], initWithSnapshot, timestamp, snapShotJson));
					}
					await Promise.all(allInits);
				});
			} else {
				let allInits: any[] = [];
				for (let i = 0; i < modules.length; i++) {
					allInits.push(this.initModule(modules[i], false, undefined, undefined));
				}
				await Promise.all(allInits);
			}
		} catch (error) {
			console.error('FB: initModules: ' + error.message, error.stack);
		}
	}

	public async unsubscribeModule(module: FireStoreModule, clearFetchTime: boolean = true) {
		if (this.unsubscribe[this.getUniqueKey(module)]) {
			this.unsubscribe[this.getUniqueKey(module)]();
			delete this.unsubscribe[this.getUniqueKey(module)];
		}

		if (clearFetchTime) {
			let fetchTimeKey = this.getOpsKey(module);
			let fbFetchOp = this.getState().opsState.byId[fetchTimeKey] || {};
			fbFetchOp.value = null;
			fbFetchOp.updatedAt = new Date();

			await this.dispatch(updateOperation(fbFetchOp));
		}
	}

	private getModule(collection: string) {
		if (IsMinimalRhymeSite) {
			return collection === 'rhymes' ? new RhymesFireStoreModule() : undefined;
		}

		let forWeb = !isFakeWebViewEnv() && !isAdminOrTest(this.getState());

		switch (collection) {
			case 'articles':
				return new ArticlesFireStoreModule(forWeb);
			case 'publications':
				return new PublicationsFireStoreModule();
			case 'editions':
				return new EditionsFireStoreModule();
			case 'booklets':
				return new BookletsFireStoreModule();
			case 'rhymes':
				return forWeb ? undefined : new RhymesFireStoreModule();
			case 'notifications':
				return new NotificationsFireStoreModule();
			case 'quotes':
				return new QuotesFireStoreModule();
			case 'hd':
				return new HDFireStoreModule();
			case 'features':
				return forWeb ? undefined : new FeaturesFireStoreModule();
			case 'configs':
				return new ConfigsFireStoreModule();
		}
	}

	public async processModuleChange(module: FireStoreModule, updatedAt: FBTimestamp, data?: any) {
		let db = fireDB;

		let moduleName = module.getModuleName();
		let fetchTimeKey = this.getOpsKey(module);
		let fbFetchOp = this.getState().opsState.byId[fetchTimeKey] || {};
		let fetchTime: FBTimestamp = fbFetchOp.value;

		if (!fetchTime || !fetchTime.seconds) {
			fetchTime = moduleName === 'sandesh' || moduleName === 'playlists' ? fbFromDate(new Date(0)) : fbFromDate(new Date(1722951162260));
		} else {
			fetchTime = new Timestamp(fetchTime.seconds, fetchTime.nanoseconds);
		}

		if (data) {
			console.log('Tracker: Adding changes for module: ' + moduleName);
			await this.processChanges(module, fetchTimeKey, [new SnapChange(data)], true);
		} else {
			if (fetchTime.seconds * 10 ** 9 + fetchTime.nanoseconds < updatedAt.seconds * 10 ** 9 + updatedAt.nanoseconds) {
				console.log('Tracker: Fetching changes for module: ' + moduleName);
				let result = await getDocs(query(module.getFBWatchQuery(db), where('updatedAt', '>', fetchTime), orderBy('updatedAt', 'desc')));
				await this.processChanges(module, fetchTimeKey, result.docChanges(), true);
			} else {
				console.log('Tracker: Already updated for module: ' + moduleName);
			}
		}
	}

	private async processTrackerChanges(fetchTimeKey_: string, changes: FBDocChange[]) {
		try {
			let now = new Date();
			let fbFetchOp = this.getState().opsState.byId[fetchTimeKey_] || { key: fetchTimeKey_, createdAt: now };
			let maxUpdatedTime: FBTimestamp = fbFetchOp.value;
			if (!maxUpdatedTime) {
				maxUpdatedTime = fbFromDate(new Date(0));
			}

			let db = fireDB;

			for (let change of changes) {
				if (change.type === 'added' || change.type === 'modified') {
					let data = change.doc.data();
					let updatedAt: FBTimestamp = data.updatedAt;
					let collection = data.collection ?? change.doc.id;
					let value = data.data;
					if (value) {
						value.id = data.recordId;
					}

					if (
						updatedAt.seconds > maxUpdatedTime.seconds ||
						(updatedAt.seconds === maxUpdatedTime.seconds && updatedAt.nanoseconds > maxUpdatedTime.nanoseconds)
					) {
						maxUpdatedTime = updatedAt;
					}

					let module = this.getModule(collection);
					if (!module) {
						console.log('Tracker: Unsupported Collection Name: ' + collection);
						continue;
					}

					await this.processModuleChange(module, updatedAt, value);
				}
			}

			fbFetchOp.value = maxUpdatedTime;
			fbFetchOp.updatedAt = now;

			await this.dispatch(updateOperation(fbFetchOp));
		} catch (error) {
			console.error('FB: processTrackerChanges: ' + error.message, error);
		}
	}

	private async processSnapshotJson(type: string, snapShotJson: any = {}) {
		let maxUpdatedTime = fbFromDate(new Date(0));
		for (let collection in snapShotJson) {
			if (collection === '__now__') {
				maxUpdatedTime = fbFromDate(new Date(snapShotJson[collection]));
				continue;
			}

			let module = this.getModule(collection);

			if (!module) {
				continue;
			}

			let fetchTimeKey = this.getOpsKey(module);
			let moduleName = module.getModuleName();
			let snapData = snapShotJson[collection];
			if (snapData) {
				let changes = snapData.map((record) => new SnapChange(record));
				console.log(`Processing ${type} snapshot for module( ${moduleName} ): ${changes.length}`);
				let updatedTime = await this.processChanges(module, fetchTimeKey, changes, false);

				if (maxUpdatedTime.seconds * 10 ** 9 + maxUpdatedTime.nanoseconds < updatedTime.seconds * 10 ** 9 + updatedTime.nanoseconds) {
					maxUpdatedTime = updatedTime;
				}
			}
		}

		return maxUpdatedTime;
	}
	public async initTrackerModule(initWithSnapshot?: boolean) {
		try {
			if (initWithSnapshot === true) {
				await this.processSnapshot(async (initWithSnapshot, snapShotJson, snapshotTime, incSnapShotJson) => {
					let fetchTime: FBTimestamp | undefined;
					if (initWithSnapshot && snapshotTime) {
						let ogUpdatedTime = await this.processSnapshotJson('Original', snapShotJson);
						if (incSnapShotJson) {
							let incUpdatedTime = await this.processSnapshotJson('Incremental', incSnapShotJson);
							fetchTime =
								incUpdatedTime.seconds && incUpdatedTime.seconds * 1000 > snapshotTime
									? new Timestamp(incUpdatedTime.seconds, incUpdatedTime.nanoseconds)
									: fbFromDate(new Date(snapshotTime));
							// snapshotTime.seconds = snapshotTime.seconds + 1;

							console.log('snapshotTime', fetchTime.toDate());
						} else {
							let incFetchTimeKey = 'IncrementalSnapshot';
							let incFbFetchOp: Operation | undefined = this.getState().opsState.byId[incFetchTimeKey] || {};
							fetchTime = fbFromDate(new Date(incFbFetchOp.value));
						}
					}

					await this.registerTracker(fetchTime);
				});
			}
		} catch (error) {
			console.error('FB: initTrackerModule: ' + error.message, error.stack);
		}
	}

	public async registerTracker(snapshotTime?: FBTimestamp) {
		return new Promise(async (resolve, reject) => {
			try {
				let db = fireDB;
				let module = new TrackerFireStoreModule(!isFakeWebViewEnv() && !isAdminOrTest(this.getState()));

				let fetchTimeKey_ = this.getOpsKey(module);
				let fbFetchOp_ = this.getState().opsState.byId[fetchTimeKey_] || {};
				let fetchTime_: FBTimestamp = fbFetchOp_.value;

				let moduleName = module.getModuleName();

				if (!fetchTime_) {
					if (snapshotTime) {
						fetchTime_ = snapshotTime;
					} else {
						fetchTime_ = fbFromDate(new Date(0));
					}
				} else {
					if (snapshotTime && snapshotTime.seconds > fetchTime_.seconds) {
						fetchTime_ = snapshotTime;
					} else {
						fetchTime_ = new Timestamp(fetchTime_.seconds, fetchTime_.nanoseconds);
					}
				}

				console.log('registerTracker - fetchTime', fetchTime_.toDate());

				let watchQuery = module.getFBWatchQuery(db);

				let promiseNotHandled = true;

				this.unsubscribe[this.getUniqueKey(module)] = onSnapshot(
					query(watchQuery, where('updatedAt', '>', fetchTime_), orderBy('updatedAt', 'asc')),
					async (snapshot) => {
						try {
							console.log('Updates for module(' + moduleName + '):');
							if (snapshot.metadata.fromCache === true) {
								// console.log('Ignoring updates from cache');
								// return;
							}
							let updates = snapshot.docChanges();

							if (updates.length === 0) {
								// return;
							}

							await this.processTrackerChanges(fetchTimeKey_, updates);

							promiseNotHandled && resolve(true);
							promiseNotHandled = false;
							console.log('Processed updates for module(' + moduleName + ')', updates.length);
						} catch (error) {
							console.error('FB: Tracker: onSnapshot: ' + error.message, error.stack);
							promiseNotHandled && reject(error);
							promiseNotHandled = false;
						}
					},
					(error) => {
						console.error('FB: Tracker: onSnapshot: ' + error.message, error.stack);
					}
				);
			} catch (error) {
				console.error('FB: Tracker: initModule: ' + error.message, error.stack);
			}
		});
	}
}

export const getServerTime = () => {
	return new Date().getTime();
};

export const generateRecordId = (collectionName: string) => {
	return doc(collection(fireDB, collectionName)).id;
};

export const setRecord = async (collectionName: string, recordId, values) => {
	let db = fireDB;
	let query = collection(db, collectionName);

	values = {
		...values,
		by: fireAuth.currentUser?.uid,
		updatedAt: serverTimestamp(),
	};

	if (recordId) {
		await setDoc(doc(query, recordId), values, { merge: true });
		// await setDoc(
		// 	doc(collection(db, 'tracker'), `${collectionName}.${recordId}`),
		// 	{
		// 		recordId,
		// 		collection: collectionName,
		// 		userId: fireAuth.currentUser?.uid,
		// 		data: values,
		// 		updatedAt: serverTimestamp(),
		// 	},
		// 	{ merge: true }
		// );
		logEvent('setRecord', { ...values, updatedAt: null, createdAt: null });

		// await db.collection('configs').doc(DBConfig.UpdatedAt(collection)).update({
		// 	value: firebase.firestore.FieldValue.serverTimestamp(),
		// 	by: firebase.auth().currentUser?.uid,
		// 	updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
		// });

		return recordId;
	}
};

export default firebase;
