import { Box, Card, CardHeader, Fab } from '@mui/material';
import { withRouter } from 'components/shell/WithRouter';
import { Icons } from 'config/icons';
import { filterSSNRecords, isMessagingEnabled, isSSNRequestEnabled } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { getDataSorted } from 'store/data/generic/selectors';
import { toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState, Article, Sandesh, SandeshType, UserData } from 'types';
import RecordList from '../RecordList';
import { ChatAvatar } from './ChatAvatar';
import { ChatInput } from './ChatInput';
import { SandeshTile } from './SandeshTile';

const classes = {
	fab: {
		position: 'fixed' as 'fixed',
		bottom: '75px; bottom: calc(75px + env(safe-area-inset-bottom)/2)',
		right: 30,
		zIndex: 1,
	},
};

interface SandeshListProps {
	records: Article[];
	origRecords?: Article[];
	avoidVirtual?: boolean;
	userData?: UserData;
	toggleDrawer: (content?: any) => void;
	history: any;
	SSNRequestEnabled: boolean;
	messagingEnabled: boolean;
	controlsEnabled?: boolean;
}

class Component extends React.Component<SandeshListProps, { mode: string }> {
	constructor(props) {
		super(props);

		this.state = {
			mode: 'Messages',
		};
	}

	private getItemSize(index) {
		let { records } = this.props;

		let record = records[index];
		if (!record) {
			return 139;
		}

		if (record && !record.id) {
			return 32;
		}

		if ((record as any).height) {
			return (record as any).height + ((record as any).type === SandeshType.Text ? 4 : 16);
		}
		// if (record.title.en && record.group.length > 35) {
		// 	return 175;
		// }

		return 510;
	}

	componentDidUpdate = async () => {
		if (this.props.avoidVirtual) {
			// await timeout(1000);
			// window.scrollTo(0, document.body.scrollHeight);
		} else {
			// RecordsListControl.scrollToItem(this.props.records.length - 1, 'end', 'auto');
		}
	};

	render() {
		let { avoidVirtual, records, userData, toggleDrawer, history, SSNRequestEnabled, messagingEnabled } = this.props;
		let navigate = history.navigate;

		let fab = SSNRequestEnabled && !messagingEnabled && (
			<Fab
				sx={classes.fab}
				variant="extended"
				size="medium"
				color="primary"
				onClick={() => {
					navigate('/ssnrequest', { state: { isModal: true } });
				}}
			>
				{Icons.KeyIcon}
				Request SSN Aagya Patra
			</Fab>
		);

		let chatInput = messagingEnabled && <ChatInput />;

		// let selector = <ToggleSelector values={['Messages', 'Chats']} type={this.state.mode} setType={(value) => {this.setState({mode: value})}} />

		if (!records || !records.length) {
			return (
				<>
					<Card style={{ margin: 8 }}>
						<CardHeader subheader="No messages for you! Please check later." avatar={<ChatAvatar />} />
					</Card>
					{fab}
					{chatInput}
				</>
			);
		}

		return (
			<Box sx={{ height: messagingEnabled || SSNRequestEnabled ? 'calc(100% - 64px)' : '100%' }}>
				{avoidVirtual === true ? (
					<>
						{records.map((record, index) => {
							return <SandeshTile key={record?.id ?? index} record={record} />;
						})}
					</>
				) : (
					<RecordList
						records={records}
						fetching={false}
						RecordTile={SandeshTile}
						getItemSize={this.getItemSize.bind(this)}
						initialScrollOffset={records.length * 1000}
						recordTileSpecificProps={(records, index) => {
							if (records[index].type === SandeshType.Text) {
								if (index === 0 || records[index - 1].type !== SandeshType.Text || records[index - 1].from !== records[index].from) {
									return { order: 'First' };
								}
								if (
									index === records.length - 1 ||
									records[index + 1].type !== SandeshType.Text ||
									records[index + 1].from !== records[index].from
								) {
									return { order: 'Last' };
								}
							}
							return {};
						}}
					></RecordList>
				)}
				{fab}
				{chatInput}
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState, props) {
	let userData = state.userState.userStore.userData;
	let SSNRequestEnabled = (props.controlsEnabled ?? true) && isSSNRequestEnabled(state);
	let messagingEnabled = (props.controlsEnabled ?? true) && isMessagingEnabled(state);

	if (props.records) {
		return {
			records: props.records,
			origRecords: props.records,
			userData,
			SSNRequestEnabled,
			messagingEnabled,
		};
	}

	if (!state.userState.userStore.user) {
		return {
			records: [],
			origRecords: [],
		};
	}

	let origRecords: Sandesh[] = filterSSNRecords(state, state.filteredDataState.sandesh);

	let records = [...origRecords];

	if (records) {
		let sortValue = 'Title - Ascending';
		records = getDataSorted(records, sortValue, 'sandesh-' + sortValue) as Sandesh[];

		sortValue = 'Oldest First';
		records = getDataSorted(records, sortValue, 'sandesh-' + sortValue) as Sandesh[];

		if (props.filter) {
			records = records.filter(props.filter);
		}

		if (state.tempState.searchTerm.length > 0) {
			let isInField = (title) =>
				title && typeof title === 'string' && title.toLocaleLowerCase().indexOf(state.tempState.searchTerm.toLocaleLowerCase()) >= 0;

			records = records.filter((record) => {
				if (record.type === SandeshType.SSN) {
					return isInField(record.card?.name) || isInField(record.card?.card_no.toString());
				}

				let inEnTitle = isInField(record.title?.en);
				let inHiTitle = isInField(record.title?.hi);

				let inEnMessage = isInField(record.message?.en);
				let inHiMessage = isInField(record.message?.hi);

				return inEnTitle || inHiTitle || inEnMessage || inHiMessage;
			});
		}
	}

	return {
		records,
		origRecords,
		userData,
		SSNRequestEnabled,
		messagingEnabled,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any, open?: boolean) => {
			dispatch(toggleBottomDrawer(content, open));
		},
	};
}

export const SandeshList = connect(mapStateToProps, mapDispatchToProps)(withRouter(Component));
