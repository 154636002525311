import Box from '@mui/material/Box';
import { getContentStorageUrl, isWebEnv } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import 'scss/glow.scss';
import { ApplicationState, Article, AudioCurrentState, ContentType } from 'types';

const classes = {
	root: { display: 'flex', alignItems: 'center' },
	centercircle: (theme) => ({
		height: '40%',
		width: '100%',
		opacity: 0.9,
		position: 'relative',
		scale: '0.8',
	}),
	audioslide: {
		width: '100%',
		padding: '8px 0',
	},
	aura: {
		position: 'absolute' as 'absolute',
		height: '100%',
		top: 0,
		left: 'calc(50vw - calc(40vh - 112px)/2)',
		animation: 'App-logo-spin infinite 40s linear',
		scale: '3',
		borderRadius: '50%',
		zIndex: -1,
	},
	centerimg: {
		height: '100%',
		display: 'block',
		margin: 'auto',
		border: '5px solid #e6cfc7',
		boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12), 0 0 20px #c4a300',
		borderRadius: '50%',
		scale: '0.97',
	},
	slideimg: {
		maxWidth: '100%',
		display: 'block',
		margin: 'auto',
		border: '5px solid white',
		boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
	},
	middleimg: {},
};

interface SlidesProps {
	article?: Article;
	slidesHeight?: string;
	currentTime: number;
	locale: string;
	playbackState: AudioCurrentState;
	aura?: { src: string; scale: string; bgColor: string; style?: any };

	needFullScreenSpace: (value: boolean) => void;
}

class AudioSlides extends React.Component<SlidesProps, any> {
	private currentSlideMinTime = -1;
	private currentSlideMaxTime = -1;

	constructor(props: SlidesProps) {
		super(props);

		props.needFullScreenSpace(false);
	}

	shouldComponentUpdate(newProps: SlidesProps) {
		if (this.props.article !== newProps.article || this.props.locale !== newProps.locale) {
			this.props.needFullScreenSpace(false);
			return true;
		}

		if (this.currentSlideMinTime > -1) {
			if (newProps.currentTime < this.currentSlideMinTime || newProps.currentTime >= this.currentSlideMaxTime) {
				return true;
			}
		}

		if (newProps.playbackState !== this.props.playbackState) {
			return true;
		}

		if (this.props.slidesHeight !== newProps.slidesHeight) {
			return true;
		}
		return false;
	}

	private getSlide(src: string, isAppIcon: boolean) {
		let { slidesHeight, playbackState, aura } = this.props;

		aura = aura ?? { src: '/aura.jpeg', scale: '3.0', bgColor: '#f2f4ef' };

		return (
			<Box sx={classes.root} style={{ height: isAppIcon ? slidesHeight?.replace(/%/g, 'vh') ?? '100%' : 'auto' }}>
				<Box sx={isAppIcon ? classes.centercircle : classes.audioslide}>
					<img
						style={
							playbackState === AudioCurrentState.Playing
								? { ...classes.aura, scale: aura.scale, ...aura.style, animationPlayState: 'running' }
								: { ...classes.aura, scale: aura.scale, ...aura.style, animationPlayState: 'paused' }
						}
						key={'aura-' + src}
						src={aura.src}
						// onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
						// 	event.currentTarget.src = getContentStorageUrl(ContentType.Calendar, 'no_internet.png');
						// }}
						alt=""
					/>
					<img
						style={isAppIcon ? classes.centerimg : classes.slideimg}
						key={'img-' + src}
						src={getContentStorageUrl(ContentType.PKP, src)}
						onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
							event.currentTarget.src = getContentStorageUrl(ContentType.Calendar, 'no_internet.png');
						}}
						alt=""
					/>
				</Box>
			</Box>
		);
	}

	private getCurrentSlide = () => {
		let { article, slidesHeight } = this.props;

		if (isWebEnv() && article?.mediaType === 'video') {
			return (
				<Box sx={classes.root} style={{ height: slidesHeight?.replace(/%/g, 'vh') ?? '100%' }}>
					<Box
						component="video"
						playsInline
						controlsList="nodownload"
						controls
						sx={classes.centercircle}
						autoPlay
						style={{ scale: '1.0', opacity: 1 }}
					>
						<source src={getContentStorageUrl(ContentType.Article, article.articleType, article)} />
					</Box>
				</Box>
			);
		}

		let appIcon = this.getSlide('webapp/app_icon.png', true);

		if (!article) {
			return appIcon;
		}

		this.currentSlideMinTime = -1;
		this.currentSlideMaxTime = -1;

		return appIcon;
	};

	render() {
		let currentSlide = this.getCurrentSlide();

		return currentSlide;
	}
}

function mapStateToProps({ uxState, tempState }: ApplicationState) {
	let { audioPlaybackDetails, audioPlaybackState } = tempState;
	let { locale } = uxState;

	return {
		article: audioPlaybackDetails?.articleList[audioPlaybackDetails?.currentIndex],
		currentTime: audioPlaybackState.currentTime,
		playbackState: audioPlaybackState.currentState,
		locale: locale,
	};
}

export default connect(mapStateToProps)(AudioSlides);
