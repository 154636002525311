import { Box, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Theme } from '@mui/material/styles';
import { DialogContext } from 'components/pages/DialogPage';
import { Icons } from 'config/icons';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import { connect } from 'react-redux';
import { verifyEmailLinkSignup, verifyEmailLinkUpdate } from 'services/api/auth';
import * as Yup from 'yup';

const classes = {
	root: (theme: Theme) => ({
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: 'white',
	}),
	gsignin: (theme: Theme) => ({
		marginBottom: theme.spacing(5),
		padding: 0,
		width: '90%',
		alignSelf: 'center',
		border: '1px solid ' + theme.palette.primary.main,

		'& .g-logo': {
			height: 42,
		},

		'& .g-msg': {
			width: 'calc(100% - 42px)',
		},
	}),
	or: (theme: Theme) => ({
		textAlign: 'center' as 'center',
		backgroundColor: 'white',
		width: '10%',
		marginTop: -10,
		alignSelf: 'center',
	}),
	message: (theme: Theme) => ({
		marginBottom: theme.spacing(8),
	}),
	form: (theme: Theme) => ({
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '90%',

		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
	signinbtn: (theme: Theme) => ({
		height: 42,
		width: '90%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	}),
	newuser: (theme: Theme) => ({
		alignSelf: 'center',
		marginTop: theme.spacing(3),
	}),
};

export interface Props {
	verifyEmailLinkSignup: (email: string, password: string) => Promise<void>;
	verifyEmailLinkUpdate: (email: string, password: string) => Promise<void>;
}

class FinishEmailLinkUpdate extends React.Component<Props, any> {
	context!: React.ContextType<typeof DialogContext>;

	constructor(props) {
		super(props);

		this.state = {
			showPassword: false,
		};
	}

	render() {
		let { verifyEmailLinkUpdate } = this.props;
		let { showPassword } = this.state;

		const handleClickShowPassword = () => {
			this.setState({ showPassword: !showPassword });
		};

		const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
			event.preventDefault();
		};

		return (
			<Box sx={classes.root}>
				<Formik
					initialValues={{
						email: '',
						password: '',
						confirm: '',
					}}
					validationSchema={() =>
						Yup.object({
							email: Yup.string().email('Invalid email').required('Required'),
							password: Yup.string().required('Required').min(6),
							confirm: Yup.string()
								.required('Required')
								.oneOf([Yup.ref('password')], 'Passwords do not match'),
						})
					}
					onSubmit={async (values, { setSubmitting }) => {
						await verifyEmailLinkUpdate(values.email, values.password);
						if (this.context.popScreen) {
							this.context.popScreen();
						}
					}}
				>
					{({ submitForm, isSubmitting }) => (
						<Box component={Form} sx={classes.form} translate="yes">
							<Field
								component={TextField}
								name="email"
								type="email"
								label="New Email Address"
								autoComplete="off"
								autoCapitalize="off"
								autoCorrect="off"
								spellCheck="false"
							/>

							<Field
								component={TextField}
								type={showPassword ? 'text' : 'password'}
								label="New Password"
								name="password"
								autoComplete="off"
								autoCapitalize="off"
								autoCorrect="off"
								spellCheck="false"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} tabIndex={-1} size="large">
												{showPassword ? Icons.Visibility : Icons.VisibilityOff}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>

							<Field
								component={TextField}
								type={showPassword ? 'text' : 'password'}
								label="Confirm Password"
								name="confirm"
								autoComplete="off"
								autoCapitalize="off"
								autoCorrect="off"
								spellCheck="false"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} tabIndex={-1} size="large">
												{showPassword ? Icons.Visibility : Icons.VisibilityOff}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>

							<Button sx={classes.signinbtn} variant="contained" color="primary" type="submit" onClick={submitForm} disabled={isSubmitting}>
								Update
							</Button>
						</Box>
					)}
				</Formik>
			</Box>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		verifyEmailLinkSignup: async (email: string, password: string) => {
			await dispatch(verifyEmailLinkSignup({ email, password }));
		},
		verifyEmailLinkUpdate: async (email: string, password: string) => {
			await dispatch(verifyEmailLinkUpdate(email, password));
		},
	};
}

export default connect(undefined, mapDispatchToProps)(FinishEmailLinkUpdate);
