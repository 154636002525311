import { Box, Collapse, Drawer, Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Theme } from '@mui/material/styles';
import { DistributiveOmit } from '@mui/types';
import { IsMinimalRhymeSite } from 'config';
import { Icons } from 'config/icons';
import { AppAdminRouteGroups, AppRouteGroups, minimalRouteGroups, sandeshListRoute, WebRouteGroups } from 'config/route';
import { User } from 'firebase/auth';
import { getWebAppAssetPath, isAdmin, isFakeWebViewEnv, isMessagingEnabled, isSSNRequestEnabled } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { updateOperation } from 'store/data/ops/actions';
import { closeDrawer, openDrawer } from 'store/temp/actions';
import { ApplicationState, Operation, RouteGroup, RouteProps, UserData } from 'types';

function ListItemLink(props: RouteProps) {
	let { icon, primary, to, state, exactTo, onClick } = props;
	const [open, setOpen] = React.useState(false);

	to = exactTo ?? to.split('/:')[0];

	const handleClick = (e) => {
		e.stopPropagation();

		setOpen(!open);
	};

	const renderLink = React.useMemo(
		() =>
			React.forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((itemProps, ref) =>
				onClick ? <Link state={state} ref={ref} {...itemProps} /> : <RouterLink to={to} state={state} ref={ref} {...itemProps} />
			),
		[to, state]
	);

	// const renderButton = React.forwardRef<any, any>((itemProps, ref) => (
	// 	<Link onClick={onClick} state={state} ref={ref} {...itemProps} />
	// ))

	if (state?.subRoutes) {
		return (
			<div>
				<li>
					<ListItem button onClick={handleClick} data-nested={true}>
						{icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
						<ListItemText primary={primary} />
						{open ? Icons.ExpandLess : Icons.ExpandMore}
					</ListItem>
				</li>
				<Collapse in={open} timeout="auto" unmountOnExit>
					<List component="div" disablePadding style={{ paddingLeft: 32 }}>
						{state.subRoutes.map((route, index) => (
							<ListItemLink
								key={primary + '-route-link-key-' + index}
								to={route.to}
								exactTo={route.exactTo}
								onClick={route.onClick}
								primary={route.primary}
								icon={route.icon || icon || Icons.Description}
								state={route.state}
							/>
						))}
					</List>
				</Collapse>
			</div>
		);
	}

	return (
		<li>
			<ListItem button onClick={onClick} target={state?.target} component={renderLink}>
				{icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
				<ListItemText primary={primary} />
			</ListItem>
		</li>
	);
}

const drawerWidth = 300; //'80vw'; //270;

const classes = {
	root: {
		display: 'flex',
	},
	drawerNav: (theme: Theme) => ({
		width: drawerWidth,
		flexShrink: 0,
		// width: { sm: drawerWidth },
		// flexShrink: { sm: 0 },
		// overflowX: 'hidden' as const,
	}),
	drawer: {
		'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, overflowX: 'hidden', overflowY: 'hidden' },
	},
	appBar: (theme: Theme) => ({
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
		},
	}),
	menuButton: (theme: Theme) => ({
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	}),
	banner: {
		width: '100%',
	},
	ssdn: {
		fontSize: '1.4rem',
	},
	rhyme: {
		fontSize: '1rem',
		padding: '0.5rem 0',

		'& > .locale-hi': {
			fontSize: '1.7rem',
		},

		'& .rhyme-line.rhyme-line-justified': {
			marginBottom: '-0.3em',
		},
	},
};

function ListRouter(props: { routeGroups: RouteGroup[] }) {
	return (
		<>
			{props.routeGroups.map((routeGroup, index) => {
				return (
					<React.Fragment key={'route-groupe-key-' + index}>
						{index > 0 && <Divider />}
						<List>
							{routeGroup.routes.map((route, index) => (
								<ListItemLink
									key={'route-link-key-' + index}
									to={route.to}
									exactTo={route.exactTo}
									onClick={route.onClick}
									primary={route.primary}
									icon={route.icon || Icons.Description}
									state={route.state}
								/>
							))}
						</List>
					</React.Fragment>
				);
			})}
		</>
	);
}

interface ResponsiveDrawerProps {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	container?: Element;
	isDrawerOpen: boolean;
	userData?: UserData;
	user?: User;
	onDrawerClose: () => void;
	onDrawerOpen: () => void;
	SSNRequestEnabled?: boolean;
	messagingEnabled?: boolean;
	todaysHDAcked?: Operation;
	unackHD: () => void;
}

function ResponsiveDrawer(props: ResponsiveDrawerProps) {
	const { container, user, userData, isDrawerOpen, onDrawerClose, SSNRequestEnabled, messagingEnabled, todaysHDAcked, unackHD } = props;
	// const theme = useTheme();
	// const [mobileOpen, setMobileOpen] = React.useState<Function>(props.isDrawerOpen);

	const onClose = (e) => {
		props.onDrawerClose();
	};

	let admin = isAdmin(user, userData);
	let routeGroups = admin ? AppAdminRouteGroups : isFakeWebViewEnv() ? AppRouteGroups : WebRouteGroups;
	routeGroups = [...routeGroups];

	if (SSNRequestEnabled || messagingEnabled) {
		routeGroups.splice(admin ? 1 : 0, 0, { routes: [sandeshListRoute] });
	}

	if (todaysHDAcked?.value && routeGroups[routeGroups.length - 2].routes[1].primary !== 'Historical Day') {
		routeGroups[routeGroups.length - 2].routes.splice(1, 0, {
			icon: Icons.EventRepeatIcon,
			primary: 'Historical Day',
			onClick: unackHD,
			to: '/',
		});
	} else if (todaysHDAcked?.value === false && routeGroups[routeGroups.length - 2].routes[1].primary === 'Historical Day') {
		routeGroups[routeGroups.length - 2].routes.splice(1, 1);
	}
	const drawer = (
		<div role="presentation" onClick={onClose} onKeyDown={onClose} style={{ overflowY: 'auto' }}>
			<ListRouter routeGroups={IsMinimalRhymeSite ? minimalRouteGroups : routeGroups} />
		</div>
	);

	return (
		<Box sx={classes.root}>
			<Box component="nav" sx={classes.drawerNav}>
				<Drawer
					container={container}
					variant="temporary"
					anchor={'left'}
					open={isDrawerOpen}
					onClose={onDrawerClose}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={classes.drawer}
				>
					<Box component="img" sx={classes.banner} src={getWebAppAssetPath('bg/dark/js3.jpeg')} alt="" />
					{drawer}
				</Drawer>
			</Box>
		</Box>
	);
}

function mapStateToProps(state: ApplicationState) {
	let { tempState, userState, opsState } = state;
	let { isDrawerOpen } = tempState;

	return {
		isDrawerOpen: isDrawerOpen,
		userData: userState.userStore.userData,
		user: userState.userStore.user,
		SSNRequestEnabled: isSSNRequestEnabled(state),
		messagingEnabled: isMessagingEnabled(state),
		todaysHDAcked: opsState.byId[new AnandDate().format('YYYY-MM-DD')],
	};
}

function mapDispatchToProps(dispatch) {
	return {
		onDrawerClose: () => {
			dispatch(closeDrawer());
		},
		onDrawerOpen: () => {
			dispatch(openDrawer());
		},
		unackHD: () => {
			dispatch(updateOperation({ key: new AnandDate().format('YYYY-MM-DD'), value: false, createdAt: new Date(), updatedAt: new Date() }));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveDrawer);
