import { Fade, Zoom } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { grey } from '@mui/material/colors';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { lighten, styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Icons } from 'config/icons';
import { PrimaryMainColor } from 'config';
import { isFakeWebViewEnv, isMobile } from 'helpers';
import * as React from 'react';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

const defaultFrontalBG = lighten(PrimaryMainColor, 0.1); //blue[400];

const classes = {
	root: (props) => (theme) => ({
		'& .MuiPaper-root': props.frontal
			? {
					backgroundColor: defaultFrontalBG,
			  }
			: {},
		'& .MuiDialogActions-root': props.frontal
			? {
					backgroundColor: theme.palette.primary.main,
			  }
			: {},
	}),
	appBar: (props) =>
		props.frontal
			? {
					position: 'relative',
					backgroundColor: defaultFrontalBG,

					'& .MuiSvgIcon-root': {
						width: '1.5em',
						height: '1.5em',
						color: 'rgba(255, 255, 255, 0.8)',
					},
			  }
			: {
					position: 'relative',
			  },
	title: (theme) => ({
		marginLeft: theme.spacing(1),
		flex: 1,
		fontSize: '1.75rem',
	}),
	content: (theme) => ({
		padding: 0,
		paddingBottom: theme.spacing(3),
	}),
	drawerType: {
		'& .MuiDialog-paper': {
			height: 'auto',
			alignSelf: 'flex-end',
			maxHeight: '100%',

			'& .MuiDialogContent-root:first-of-type': {
				paddingTop: 0,
			},
		},
	},
};

const UpTransition = React.forwardRef(function Transition(props: TransitionProps & { children: ReactElement<any, any> }, ref: React.Ref<unknown>) {
	return (
		<Slide direction="up" ref={ref} {...props}>
			{props.children}
		</Slide>
	);
});

const ZoomTransition = React.forwardRef(function Transition(props: TransitionProps & { children: ReactElement<any, any> }, ref: React.Ref<unknown>) {
	return (
		<Zoom ref={ref} {...props}>
			{props.children}
		</Zoom>
	);
});

const LeftTransition = React.forwardRef(function Transition(props: TransitionProps & { children: ReactElement<any, any> }, ref: React.Ref<unknown>) {
	return (
		<Slide direction="left" ref={ref} {...props}>
			{props.children}
		</Slide>
	);
});

const FadeTransition = React.forwardRef(function Transition(props: TransitionProps & { children: ReactElement<any, any> }, ref: React.Ref<unknown>) {
	return (
		<Fade ref={ref} {...props}>
			{props.children}
		</Fade>
	);
});

interface DialogPageProps {
	title: string;
	children?: React.ReactNode;
	transition?: boolean | string;
	showAppBar?: boolean;
	onClose?: () => boolean | void;
	onEntered?: (dialog?: any, contentRef?: any) => void;
	goBackOnClose?: boolean;
	goBackTo?: string;
	extraAppBarElement?: React.ReactNode;
	actionButtons?: React.ReactNode;
	style?: any;
	appBarClassName?: string;
	appBarSx?: any;
	drawerType?: any;
	scroll?: string;
	disableBackdropClick?: boolean;
	maxWidth?: DialogProps['maxWidth'];
	screenKey?: string;
	dark?: boolean;
	disableEnforceFocus?: boolean;
	open?: boolean;
	frontal?: boolean;
}

export interface Screen {
	title: string;
	key: string;
	dark?: boolean;
	props?: any;
}

export const DialogContext = React.createContext({
	screens: [{ title: '', key: '' }] as Screen[],
	pushScreen: (screen: Screen, screenProps: any = {}) => {},
	replaceScreen: (screen: Screen, screenProps: any = {}) => {},
	popScreen: (force?: boolean) => {},
	purgePrevScreens: () => {},
	setDisablePop: (value: boolean) => {},
	close: () => {},
});

const DialogActions = styled(MuiDialogActions)({
	borderTop: '1px solid darkgrey',
	paddingBottom: '8px; padding-bottom: calc(8px + env(safe-area-inset-bottom)/2)',
});

const Content = (props: any) => {
	let dialogContentRef = props.dialogContentRef; // React.createRef();
	const [screens, setScreens] = React.useState([{ key: props.screenKey ?? 'main', title: props.title, dark: props.dark, props: {} } as Screen]);

	const [disablePop, setDisablePop] = React.useState(false);

	const purgePrevScreens = () => {
		if (screens.length > 1) {
			setScreens([screens[screens.length - 1]]);
		}
	};

	const popScreen = (force?: boolean) => {
		if (screens.length > 1) {
			setScreens(screens.slice(0, screens.length - 1));
			if (dialogContentRef.current) {
				(dialogContentRef.current as any).scrollTo({
					top: 0,
				});
			}
			return;
		}

		if (props.onClose && props.onClose() === false && force !== true) {
			return;
		}

		props.handleClose();
	};

	const pushScreen = (screen: Screen) => {
		setScreens([...screens, screen]);
		if (dialogContentRef.current) {
			(dialogContentRef.current as any).scrollTo({
				top: 0,
			});
		}
	};

	const replaceScreen = (screen: Screen) => {
		setScreens([...screens.slice(0, screens.length - 1), screen]);
		if (dialogContentRef.current) {
			(dialogContentRef.current as any).scrollTo({
				top: 0,
			});
		}
	};

	return (
		<>
			{props.showAppBar === false ? null : (
				<AppBar elevation={props.frontal ? 0 : 4} sx={[classes.appBar(props), props.appBarSx]} className={props.appBarClassName}>
					<Toolbar>
						{/* {props.disableBackdropClick ? null : ( */}
						<IconButton disabled={disablePop} edge="start" color="inherit" onClick={() => popScreen()} size="large">
							{screens.length === 1 && props.transition !== 'left' ? (props.frontal ? Icons.CloseIcon : Icons.Close) : Icons.ArrowBack}
						</IconButton>
						{/* )} */}
						{screens[screens.length - 1].title && props.frontal !== true ? (
							<Typography variant="h6" sx={classes.title} noWrap>
								{screens[screens.length - 1].title}
							</Typography>
						) : null}
						{props.extraAppBarElement}
					</Toolbar>
				</AppBar>
			)}

			<DialogContent
				sx={classes.content}
				ref={dialogContentRef}
				style={{
					...props.style,
					backgroundColor: props.frontal ? defaultFrontalBG : screens[screens.length - 1].dark ?? props.dark ? grey[200] : 'white',
				}}
			>
				<DialogContext.Provider
					value={{
						screens,
						pushScreen,
						replaceScreen,
						popScreen,
						purgePrevScreens,
						setDisablePop,
						close: props.handleClose,
					}}
				>
					{props.children}
				</DialogContext.Provider>
			</DialogContent>
			{props.actionButtons ? <DialogActions>{props.actionButtons}</DialogActions> : null}
		</>
	);
};

export default function DialogPage(props: DialogPageProps) {
	let dialogContentRef = React.createRef();
	const [open, setOpen] = React.useState(true);
	const theme = useTheme();
	let fullScreen = useMediaQuery(`${theme.breakpoints.down('sm')}`);
	fullScreen = useMediaQuery(`${theme.breakpoints.down('md')} and (orientation: landscape)`) || fullScreen;
	fullScreen = isFakeWebViewEnv() || fullScreen;
	let navigate = useNavigate();

	const handleClose = async (event, reason) => {
		if (reason === 'backdropClick' && props.disableBackdropClick) {
			return;
		}

		setOpen(false);

		if (props.goBackOnClose !== false) {
			// await timeout(100);

			if (navigate.length >= 2) {
				navigate(-1);
			} else {
				navigate(props.goBackTo ?? '/');
			}
		} else if (props.goBackTo) {
			navigate(props.goBackTo);
		}
	};

	const isUpMd = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<div>
			<Dialog
				sx={[classes.root(props), props.drawerType && classes.drawerType]}
				fullScreen={fullScreen}
				fullWidth={true}
				maxWidth={props.maxWidth ?? (isUpMd ? 'sm' : 'xs')}
				open={props.open ?? open}
				onClose={handleClose}
				TransitionComponent={
					props.transition
						? props.transition === 'left'
							? LeftTransition
							: UpTransition
						: props.frontal
						? FadeTransition
						: props.transition === false
						? undefined
						: ZoomTransition
				}
				TransitionProps={{
					exit: false,
					onEntered: (dialog) => {
						if (props.onEntered) {
							props.onEntered(dialog, dialogContentRef);
						}
					},
				}}
				scroll={(props.scroll ?? (isMobile() ? 'paper' : 'body')) as 'body' | 'paper'}
				disableEnforceFocus={props.disableEnforceFocus ?? false}
				disableEscapeKeyDown={props.disableBackdropClick}
				onBackdropClick={() => {}}
			>
				<Content dialogContentRef={dialogContentRef} {...props} handleClose={handleClose} />
			</Dialog>
		</div>
	);
}
